// Function to format the number as 'xxxxx xxx xxx'
const formatPhoneNumber = (number) => {

    // Remove non-numeric characters and format it
    const cleaned = ('' + number).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{4})(\d{3})(\d{4})$/);

    if (match) {
        return `${match[1]} ${match[2]} ${match[3]}`;
    }

    return number; // Return the original number if it doesn't match the pattern
};

export default formatPhoneNumber;