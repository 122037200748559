import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import DisplayPost from '../../components/DisplayPost/DisplayPost.js';
import Jumbotron from '../../components/Jumbotron/Jumbotron.js';
import './BlogStyles.css';

export default function BlogPage(props) {

    // State to track sidebar visibility
    const [isCollapsed, setIsCollapsed] = useState(true);
    
    // Toggle function for the sidebar
    const toggleSidebar = () => setIsCollapsed(!isCollapsed);
    
    return (
        <>
            <Helmet>
                <title>{props.blog.meta_title}</title>
                <meta name="title" content={props.blog.meta_title} />
                <meta name="description" content={props.blog.meta_desc} />
                <meta property="og:title" content={props.blog.meta_title} />
                <meta property="og:description" content={props.blog.meta_desc} />
                <meta property="og:url" content={`https://www.rob-saunders.co.uk/my-stuff/${props.blog.url}`}/>
                <meta property="og:type" content="website"/>
            </Helmet>

            <Jumbotron 
                h1={props.blog.postTitle}
                p={props.blog.postDescription}
            />  

            <main className='blog-body'>
                <div className='blog-container heavy-shadow'>
                   
                   {DisplayPost(props.blog)}
                   
                </div>
            </main>

            <div
                className={`sidebar bg-white ${isCollapsed ? 'collapsed' : 'expanded'}`}
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: '0px',
                    transition: 'all 0.3s ease-in-out',
                    borderRadius: '10px',
                    zIndex: 1000,
                }}
                >
                <div
                    onClick={toggleSidebar}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        background: '#f7f7f7',
                        borderBottom: '1px solid #bbb',
                        borderTopLeftRadius: '10px',
                        borderTopRightLeftRadius: '10px',
                    }}
                >
                    <h3 style={{ margin: 0 }}>Quick Go...</h3>
                    {!isCollapsed && (
                    <span
                        onClick={toggleSidebar}
                        style={{ cursor: 'pointer', fontSize: '18px', padding: '0 10px' }}
                    >
                        &#x2716; {/* Unicode character for X */}
                    </span>
                    )}
                </div>
                {!isCollapsed && (
                    <div
                        style={{
                            maxHeight: '250px', // Container height to allow for scrolling
                            overflowY: 'auto', // Vertical scrolling
                            padding: '10px',
                        }}
                    >
                        <ul style={{ padding: '10px' }}>
                            <li style={{ margin: '10px 0' }}>
                                <NavLink
                                    to={'#introduction'}
                                    style={{ textDecoration: 'none', color: '#333' }}
                                >
                                    <i className="fas fa-lightbulb" style={{ marginRight: '5px' }}></i>
                                    Introduction
                                </NavLink>
                            </li>

                            {props.blog.postContent.map((s, i) => (
                                <li key={i} style={{ margin: '10px 0' }}>
                                    <NavLink
                                        to={`#${i}`}
                                        style={{ textDecoration: 'none', color: '#333' }}
                                    >
                                        <i className="fas fa-lightbulb" style={{ marginRight: '5px' }}></i>
                                        {s.sectionTitle}
                                    </NavLink>
                                </li>
                            ))}

                            <li style={{ margin: '10px 0' }}>
                                <NavLink
                                    to={'#conclusion'}
                                    style={{ textDecoration: 'none', color: '#333' }}
                                >
                                    <i className="fas fa-lightbulb" style={{ marginRight: '5px' }}></i>
                                    Conclusion
                                </NavLink>
                            </li>

                        </ul>
                    </div>
                )}
                </div>
        </>
    )
};